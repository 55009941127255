* {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*::before,
*::after {
  content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
a img {
  border: none;
  outline: none;
}
a {
  outline: 0;
  text-decoration: none;
}
@font-face {
  font-family: "poppins-bold";
  src: url("./fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "poppins-medium";
  src: url("./fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "poppins-regular";
  src: url("./fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

.m-t-0 {
  margin-top: 0px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.w-100 {
  width: 100% !important;
}
.w-50px {
  width: 50px !important;
}
.w-250px {
  width: 250px !important;
}
.w-300px {
  width: 300px !important;
}
.w-400px {
  width: 400px !important;
}
.w-fluid {
  width: 100% !important;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center;
}
.txt-link {
  color: #5982eb;
  font-size: 12px;
}
.border-b {
  border-bottom: 1px solid #ebeff4;
}
.h-50px {
  height: 50px;
}
.pad-lr-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.bold {
  font-family: "poppins-bold";
}
.close-btn-rounded {
  position: relative;
  float: left;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  background-color: #eef1f3;
  margin-left: 5px;
  margin-top: 24px;
  color: #8b9ab1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.close-btn-rounded:hover {
  background-color: #fb5a64;
  color: #fff;
}
.bold-lg {
}
.float-r {
  float: right !important;
}
.float-l {
  float: left !important;
}
.no-float {
  float: initial !important;
}
.lrg-txt {
  font-size: 32px;
  color: #77879f;
  font-weight: 900;
}
.sub-txt {
  font-size: 12px;
  color: #a1afc5;
}
.brg-name {
  font-size: 14px;
  color: #788496;
}
.txt-in-form {
  font-size: 14px;
  line-height: 50px;
  color: #a1afc5;
}

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
body {
  font-family: "poppins-regular";
  color: #292c41;
  letter-spacing: -0.07px;
  font-weight: 300;
  background-color: #f4f7f9;
  /*    height: 100%;*/
}

/*MAIN STRUCTURE*/
.main-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  min-width: 1265px;
}

/*LEFT MODULE*/
.left-module {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 104px;
  z-index: 1;
}

/*RIGHT MODULE*/
.right-module {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 104px;
}

.left-module .anita-logo {
  position: relative;
  height: 60px;
  margin: 0 auto;
  background-image: url(./img/anita%20logo.png);
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: center;
}

/*MAIN MENU MODULE*/
.main-menu-module {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 20px 20px;
}
.main-menu-module .menu-wrap {
  position: relative;
  background-color: #ebedf0;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  padding-top: 5px;
}
.main-menu-module .menu-wrap .menu-list {
  position: relative;
  width: 100%;
  width: 54px;
  height: 54px;
  border-radius: 10px;
  font-size: 24px;
  margin: 0 auto;
  color: #dba037;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 5px;
}
.main-menu-module .menu-wrap .menu-list:hover {
  background-color: #fff;
  border: 1px solid #dfe5ef;
}
.main-menu-module .menu-wrap .menu-list.active {
  background-color: #fff;
  color: #7a2712;
  border-radius: 10px;
  border: 1px solid #d8dee8;
}
.main-menu-module .menu-wrap .menu-list .menu-popup {
  /*    display: none;*/
  position: absolute;
  left: 58px;
  top: 0;
  min-width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 0px rgba(45, 46, 75, 0.23),
    0px 2px 8px 0px rgba(45, 46, 75, 0.13);
  z-index: 1;
  padding: 12px 8px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup .menu-title {
  font-family: "poppins-bold";
  text-align: left;
  color: #25344b;
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 8px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup .popup-menu-wrap {
  display: flex;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul.row {
  flex: 1;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul {
  list-style: none;
  width: 230px;
  padding: 0 8px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 12px;
  text-align: left;
  color: #737f8b;
  display: flex;
  align-items: center;
  line-height: 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 8px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li:hover {
  color: #272e36;
  background-color: #f4f6f8;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li .icon {
  margin-right: 10px;
  font-size: 24px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li .arrow {
  position: absolute;
  right: 10px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li .text {
  display: flex;
}
.main-menu-module .menu-wrap .menu-list .tooltip {
  position: absolute;
  min-width: 84px;
  left: 64px;
  padding: 5px 12px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 11px;
  color: #737f8b;
  box-shadow: 0px 10px 25px 0px rgba(45, 46, 75, 0.23),
    0px 2px 8px 0px rgba(45, 46, 75, 0.13);
}
.main-menu-module .menu-wrap .menu-list .tooltip:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 8px;
  width: 5px;
  height: 10px;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li.menu-green {
  background-color: #d6f8ed;
  border: solid 1px #95ecd0;
  color: #379d8b;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li.menu-orange {
  background-color: #ffe9e4;
  border: solid 1px #fad1c8;
  color: #b8786a;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li.menu-purple {
  background-color: #e7e7ff;
  border: solid 1px #c3c3ff;
  color: #6a6a98;
}
.main-menu-module .menu-wrap .menu-list .menu-popup ul li.menu-blue {
  background-color: #e4f7ff;
  border: solid 1px #ade2f8;
  color: #289aca;
}

/*MAIN MENU MODULE*/

/*MAIN TAB MODULE*/
.main-tab-module {
  position: relative;
  width: 100%;
  height: 60px;
  /*    background-color: red;*/
}

/*TAB MODULE*/
.tab-menus-container {
  position: absolute;
  right: 240px;
  left: 0;
  bottom: 0;
  height: 40px;
  /*    background-color: blue;*/
  display: flex;
  overflow-x: auto;
  margin-right: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tab-menus-container::-webkit-scrollbar {
  display: none;
}

.tab-menus-container .tab-menu {
  position: relative;
  padding: 0 10px 0 16px;
  height: 40px;
  font-size: 12px;
  color: #77879f;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  background-color: #ebedf0;
  margin: 0 2px;
  border-radius: 8px 8px 0 0;
}
.tab-menus-container .tab-menu:hover {
  color: #415471;
}
.tab-menus-container .tab-menu.active {
  font-weight: 700;
  color: #25344b;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}
.tab-menus-container .close {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  /*    background-color: #d1d5d8;*/
  margin-left: 5px;
  color: #8b9ab1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.tab-menus-container .close:hover {
  background-color: #fb5a64;
  color: #fff;
}
.tab-menus-container .tab-menu.active .close {
  background-color: #eef1f3;
}
.tab-menus-container .tab-menu.active .close:hover {
  background-color: #fb5a64;
  color: #fff;
}
.tab-menus-container .tab-menu:first-child {
  margin-left: 0;
}

/*FLEX CENTER*/
.flex-center {
  display: flex;
  align-items: center;
  /*    justify-content: center;*/
}

.flex-center-div{
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; 
}

/*FLEX BETWEEN*/
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /*    column-gap: 10px;*/
}

/*FLEX CENTER*/
.flex-bottom {
  display: flex;
  align-items: flex-end;
  /*    justify-content: center;*/
}

/*ACCOUNT MODULE*/
.account-module {
  position: absolute;
  height: 60px;
  width: 226px;
  right: 15px;
  top: 0;
  /*    background: red;*/
  display: flex;
  align-items: center;
}
.account-module .notif-wrap {
  position: relative;
  height: 24px;
  width: 24px;
  /*    background: blue;*/
  font-size: 24px;
  line-height: 20px;
}
.account-module .notif-wrap .show-notif {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 14px;
  background-color: #ea5a7c;
  border: 2px solid #f4f7f9;
  width: 13px;
  height: 13px;
}
.account-module .notif-wrap .show-notif.empty {
  display: none;
}

/*PROFILE SECTION*/
.account-module .account-profile {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  height: 32px;
  width: 187px;
  margin-left: 15px;
  padding: 3px;
  display: flex;
  cursor: pointer;
}
.account-module .account-profile:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
}
.account-module .account-profile .photo {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
}
.account-module .account-profile .photo img {
  width: 100%;
  object-fit: cover;
}
.account-module .account-profile .name {
  position: relative;
  width: 225px;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
  padding-left: 5px;
}
.account-module .account-profile .toggle-btn {
  font-size: 21px;
}
.account-module .account-profile .popup-profile {
  position: absolute;
  width: 250px;
  right: 0;
  top: 40px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0px 10px 25px 0px rgb(45 46 75 / 23%),
    0px 2px 8px 0px rgb(45 46 75 / 13%);
  z-index: 1;
  padding: 18px;
}
.account-module .account-profile .popup-profile .profile-wrap {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  border-bottom: 1px solid #edf1f4;
}
.account-module .account-profile .popup-profile .profile-wrap .photo {
  width: 46px;
  height: 46px;
  border-radius: 6px;
  margin-right: 10px;
}
.account-module .account-profile .popup-profile .profile-wrap .photo img {
  width: 100%;
  object-fit: cover;
}
.account-module .account-profile .popup-profile .profile-wrap .name-profil {
  position: relative;
  font-size: 12px;
  font-weight: 700;
}
.account-module
  .account-profile
  .popup-profile
  .profile-wrap
  .name-profil
  .email-add {
  color: #a1afc5;
  font-weight: 400;
}
.account-module .account-profile .popup-profile ul li {
  list-style: none;
  font-size: 14px;
  margin: 10px 0;
}
.account-module .account-profile .popup-profile ul li:last-child {
  margin-bottom: 0;
}

/*CONTENT MODULE*/
.main-content-wrap {
  position: absolute;
  left: 104px;
  top: 60px;
  bottom: 15px;
  right: 15px;
  background-color: #fff;
  border-radius: 0 14px 14px;
  min-width: 900px;
  overflow: hidden;
}

/*TAB CONTENT MODULE*/
.tab_content_module {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  border-bottom: 1px solid #ebedf0;
  display: flex;
}

.tab_content_module .tab-menu-wrap {
  position: relative;
  /*    width: 100%;*/
  height: 50px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.tab_content_module .tab-menu-wrap::-webkit-scrollbar {
  display: none;
}
.tab_content_module .tab_menu_content {
  position: relative;
  padding: 0 10px 0 15px;
  height: 40px;
  font-size: 12px;
  color: #77879f;
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  height: 100%;
}
.tab_content_module .tab_menu_content:first-child {
  padding: 0 15px;
}
.tab_content_module .tab_menu_content:hover {
  color: #415471;
}
.tab_content_module .tab_menu_content.active {
  font-weight: 700;
  color: #25344b;
}
.tab_content_module .tab_menu_content.active:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #d64064;
}
.tab_content_module .tab_menu_content .close {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  /*    background-color: #EEF1F3;*/
  margin-left: 3px;
  font-size: 14px;
  color: #8b9ab1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab_content_module .tab_menu_content .close:hover {
  background-color: #fb5a64;
  color: #fff;
}
.tab_content_module .new_btn_wrap {
  display: flex;
  align-items: center;
  padding: 0 12px;
}
.tab_content_module .new_btn_wrap .new_btn {
  color: #77879f;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 7px;
  border: solid 1px #e1e5ef;
  text-align: center;
  font-size: 23px;
  background-color: #ebedf2;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*TAB CONTENT MODULE*/

/*CONTENT MODULE WRAP*/
.content-module-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 15px 0 15px;
}

.form-wrap {
  position: relative;
  float: left;
  margin-right: 10px;
}
.time-select {
  position: relative;
}
.time-select .icon {
  position: absolute;
  right: 8px;
  top: 10px;
  color: #737f8b;
}
.close-btn {
  position: absolute;
  right: 15px;
  top: 13px;
  width: 27px;
  height: 27px;
  font-size: 27px;
  text-align: center;
  cursor: pointer;
}
.button {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  font-size: 18px;
  height: 35px;
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;
  padding-top: 7px;
}
.button-center{
  width: 160px;
  margin: 0 auto;
  text-align: center;
  display: block;
}
.btn-blue {
  background-color: #b4d2ff;
  color: #6b95ff;
}
.btn-blue:hover {
  background-color: #8ebbff;
  color: #5383fc;
}
.btn-green {
  background-color: #42c0b9;
  color: #fff;
}
.btn-green:hover {
  background-color: #3baaa4;
  color: #fff;
}
.btn-red {
  background-color: #ec4563;
  color: #fff;
}
.btn-red:hover {
  background-color: #d63e59;
  color: #fff;
}

.form-sub {
  font-family: "poppins-bold";
  display: inline-block;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 16px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  border: none;
  border-radius: 8px;
  outline: none;
  background: #7c2a1d;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
}

select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 13px;
  padding: 0 10px;
  height: 35px;
  background-color: #e7ecef;
  color: #737f8b;
  cursor: pointer;
  border: 1px solid #c6d4e2;
  border-radius: 0 8px 8px 0;
}

/*RADIO BUTTON CSS*/
.auto-form-container .radio-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.auto-form-container .radio-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.auto-form-container .radio-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #e7ecef;
  border: solid 1px #d7dfe4;
  border-radius: 50%;
}
.auto-form-container .radio-wrap input ~ .checkmark {
  background-color: #e7ecef;
}
.auto-form-container .radio-wrap input:checked ~ .checkmark {
  background-color: #42c0b9;
  border: initial;
}
.auto-form-container .radio-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.auto-form-container .radio-wrap input:checked ~ .checkmark:after {
  display: block;
}
.auto-form-container .radio-wrap .checkmark:after {
  top: 5.5px;
  left: 5.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}
/*RADIO BUTTON CSS*/

input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  font-family: "poppins-regular";
  height: 35px;
  padding: 12px 15px;
  border: 1px solid #c6d4e2;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 13px;
}
input:focus[type="text"],
input:focus[type="number"],
input:focus[type="password"] {
  border-color: #61acfd !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 19%);
}
.form-wrap.time-form input[type="text"] {
  border-radius: 8px 0 0 8px;
  float: left;
}
.auto-form-container textarea {
  border: none;
  height: 134px;
  width: 100%;
  resize: none;
}
.form-wrap.time-form .time-select {
  float: left;
}
.form-wrap.time-form select {
  border-left: none;
  padding-right: 25px;
}
.form-wrap .title {
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  color: #737f8b;
}
.form-wrap .form-container {
  position: relative;
  float: left;
}
.form-wrap .form-container.arrow {
  float: left;
  width: 30px;
  text-align: center;
  padding-top: 20px;
  font-size: 16px;
}
.form-date-input {
  padding-right: 33px !important;
  width: 100%;
}
.form-wrap .icon-form-date {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #aebcc5;
}
.form-wrap .satuan-form {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: #aebcc5;
  font-family: "poppins-regular";
  font-size: 13px;
}

.clear {
  clear: both;
}

/*SELECT CSS WRAP*/
.form-container .select-wrap {
  height: 45px;
}
.form-container .select-wrap .checkbox {
  margin-right: 70px;
}
/*SELECT CSS WRAP*/

/*CALENDAR UI CSS*/
.ui-datepicker {
  padding: 15px 12px;
}
.ui-widget.ui-widget-content {
  font-family: "poppins-regular";
  border-radius: 8px;
  box-shadow: 0px 6px 15px rgba(32, 51, 62, 0.2);
  border: none;
  z-index: 2 !important;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background-color: transparent;
  border: none;
  font-size: 12px;
  text-align: center;
  height: 30px;
  width: 30px;
  border-radius: 34px;
  line-height: 25px;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background-color: #67a4fe !important;
  color: #fff;
  font-weight: 700;
}
.ui-datepicker th {
  font-size: 11px;
}
.ui-widget-header {
  border: initial;
  background: initial;
  color: #25344b;
  font-size: 12px;
}
.ui-widget-header .ui-icon {
  background-image: initial;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: #43c0b9 !important;
  font-weight: 700;
}

/*NAV SETTING MODULE*/
.nav-setting-wrap {
  position: relative;
  /*    width: 100%;*/
  float: left;
  font-size: 0px;
  margin-top: 14px;
}
.nav-setting-wrap .list-setting {
  position: relative;
  display: inline-flex;
  margin-right: 10px;
}
.nav-setting-wrap .list-setting:last-child {
  margin-right: 0;
}
.nav-setting-wrap .list-setting .setting-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #b4d2ff;
  color: #6b95ff;
  font-size: 18px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.nav-setting-wrap .list-setting .setting-btn.more-btn {
  font-size: 12px;
  width: initial;
  padding: 0 12px;
}
.nav-setting-wrap .list-setting .setting-btn:hover {
  background-color: #8ebbff;
  color: #5383fc;
}
.nav-setting-wrap .list-setting .popup-setting {
  position: absolute;
  top: 45px;
  left: 0;
  width: 205px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 25px 0px rgba(45, 46, 75, 0.23),
    0px 2px 8px 0px rgba(45, 46, 75, 0.13);
  z-index: 1;
  padding: 10px;
  color: #333;
}
.nav-setting-wrap .list-setting .popup-setting.popup-right {
  right: 0;
  left: initial;
}
.nav-setting-wrap .list-setting .popup-setting ul li {
  list-style: none;
  font-size: 12px;
  display: table-cell;
}
.nav-setting-wrap .list-setting .popup-setting ul li span {
  font-size: 18px;

  vertical-align: middle;
}

/*CHECKBOX CSS*/
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: #42c0b9;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 7.5px;
  top: 5px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*FORM RIGHT MODULE*/
.form-right-side {
  position: relative;
  float: right;
}
.form-right-side .form-search-input {
  width: 245px;
  padding-right: 30px;
}
.form-right-side .form-wrap:last-child {
  margin-right: 0;
}

/*MAIN CONTENT MODULE*/
.main-content-module {
  position: relative;
  margin-top: 20px;
  height: calc(100% - 52px);
}

/*MAIN CONTENT LEFT*/
.left-content-module {
  position: relative;
  float: left;
  width: calc(100% - 70px);
  min-width: 820px;
  height: 100%;
}

/*MAIN CONTENT RIGHT*/
.right-content-module {
  position: relative;
  float: right;
  width: 55px;
  padding-top: 14px;
}

/*DATA ACTION WRAP*/
.data-action-wrap {
  float: left;
  /*    height: 100px;*/
  width: 100%;
}
.data-action-button {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 55px;
  min-height: 55px;
  border-radius: 8px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  padding: 8px 3px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}
.data-action-button.with-more-btn {
  padding: 8px 3px 3px;
}
.data-action-button .icon {
  font-size: 24px;
}
.data-action-button .more-btn {
  height: 15px;
  /*    overflow: hidden;*/
}
.data-action-button .more-btn .icon {
  font-size: 15px;
}
.green-bg {
  background-color: #42c0b9;
}
.purple-bg {
  background-color: #a48dff;
}
.blue-bg {
  background-color: #6b95ff;
}
.red-bg {
  background-color: #fb316e;
}
/*DATA ACTION WRAP*/

/*DETAIL DATA CSS*/
.main-content-module.detail-page {
  height: calc(100% - 70px);
}
.detail-data-wrap {
  height: 100%;
}
.detail-data-wrap .wrap {
  position: relative;
  float: left;
  width: 40px;
}
.detail-data-wrap .tab-menu {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 8px 0px 0px 8px;
  background-color: #e0e3e8;
  cursor: pointer;
  text-align: center;
  font-size: 22px;
  color: #a2acb6;
  margin-bottom: 3px;
  padding-top: 5px;
}
.detail-data-wrap .tab-menu.active {
  background-color: #f2f4f8;
  color: #737f8b;
}
.detail-data-wrap .content-wrap {
  float: left;
  position: relative;
  width: calc(100% - 40px);
  height: 100%;
  padding: 15px;
  border-radius: 0px 8px 8px 8px;
  background-color: #f2f4f8;
  overflow: hidden;
}

.detail-data-wrap .content-wrap.summary-wrap {
  height: calc(100% - 80px);
}
.form-search-input.detail-data {
  width: 250px;
}
#all-data-table.detail-page tr {
  background-color: #fff;
}
#all-data-table.detail-page tr:nth-child(odd) {
  background-color: #f8fafb;
}

/*SUMMARY SECTION*/
.summary-section {
  float: left;
  position: relative;
  width: 100%;
}
.summary-section .summary-list-wrap {
  position: relative;
  float: right;
  padding: 15px 0;
}
.summary-section .summary-list-wrap .summary-list {
  position: relative;
  float: left;
  width: 170px;
  height: 70px;
  margin-left: 15px;
  background-color: #f2f4f8;
  border-radius: 10px;
  padding: 10px 15px 15px 22px;
}
.summary-section .summary-list-wrap .summary-list .summary-indicator {
  position: absolute;
  width: 3px;
  height: 70%;
  left: 9px;
  top: 50%;
  margin-top: -14%;
  border-radius: 5px;
}
.summary-section .summary-list-wrap .summary-list .summary-indicator.blue {
  background-color: #7299fc;
}
.summary-section .summary-list-wrap .summary-list .summary-indicator.green {
  background-color: #40c0b9;
}
.summary-section .summary-list-wrap .summary-list .summary-indicator.red {
  background-color: #c5274d;
}
.summary-section .summary-list-wrap .summary-list .summary-indicator.orange {
  background-color: #f6b86e;
}
.summary-section .summary-list-wrap .summary-list .title {
  font-size: 12px;
  color: #70798b;
  margin-bottom: 10px;
}
.summary-section .summary-list-wrap .summary-list .nominal {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
}
/*SUMMARY SECTION*/

/*DETAIL DATA CSS*/

/*TABLE CSS*/
.tableFixHead {
  overflow-y: scroll;
  height: 100%;
  border-radius: 8px 8px 0 0;
  padding-bottom: 15px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.tableFixHead::-webkit-scrollbar {
  display: none;
}
.tableFixHead thead th {
  position: sticky;
  top: 0px;
  z-index: 2;
}
#all-data-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 12px;
  /*    overflow: hidden;*/
}

#all-data-table tbody {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
#all-data-table tr th {
  border-right: 1px solid #c6d4e2;
  border-bottom: 1px solid #c6d4e2;
}
#all-data-table tr td {
  border-right: 1px solid #e0e6ec;
  border-bottom: 1px solid #e0e6ec;
}
#all-data-table tr th:first-child {
  border-left: 1px solid #c6d4e2;
}
#all-data-table tr td:first-child {
  border-left: 1px solid #e0e6ec;
}
#all-data-table tr th {
  background: #e7ecef;
  border-top: 1px solid #c6d4e2;
  /*  text-align: left;*/
}
#all-data-table th {
  padding: 8px;
  background-color: #e7ecef;
  color: #737f8b;
  height: 42px;
}
#all-data-table td {
  padding: 8px;
  color: #737f8b;
  height: 42px;
}
#all-data-table tr:nth-child(odd) {
  background-color: #f8fafb;
}

#all-data-table tr:hover {
  background-color: #eef3f5;
  cursor: pointer;
}
#all-data-table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
#all-data-table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
#all-data-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
#all-data-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

/*LABEL PROSES TABLE*/
.lbl {
  position: relative;
  float: left;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 10px;
  /*    font-weight: 600;*/
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.3px;
}
.lbl.done,
.lbl.processed,
.lbl.paid,
.lbl.received {
  background-color: #cee8de;
  color: #118256;
}

.lbl.done:before {
  content: "selesai";
}
.lbl.received:before {
  content: "diterima Seluruhnya";
}
.lbl.processed:before {
  content: "terproses";
}
.lbl.paid:before {
  content: "lunas";
}

.lbl.wait-for-process,
.lbl.partially {
  background-color: #fdf0bf;
  color: #bd9403;
}

.lbl.wait-for-process:before {
  content: "Menunggu diproses";
}

.lbl.partially:before {
  content: "diterima sebagian";
}
.lbl.partly-process,
.lbl.shipped {
  background-color: #c5e2eb;
  color: #017ba1;
}
.lbl.partly-process:before {
  content: "sebagian diproses";
}
.lbl.shipped:before {
  content: "sedang dikirim";
}
.lbl.rejected,
.lbl.unpaid,
.lbl.canceled {
  background-color: #f2d1d3;
  color: #b50625;
}
.lbl.unpaid:before {
  content: "belum lunas";
}
.lbl.canceled:before {
  content: "dibatalkan";
}

/*TOGGLE BTN CSS*/
.toggle-btn {
  position: relative;
  display: flex;
  width: 52px;
  height: 30px;
  border-radius: 40px;
  cursor: pointer;
}
.toggle-btn.enable {
  background-color: #59d9ab;
}
.toggle-btn.disable {
  background-color: #b0c0c7;
}
.toggle-btn.disable:after,
.toggle-btn.enable:after {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 2px;
  border-radius: 30px;
  background-color: #fff;
}
.toggle-btn.disable:after {
  left: 2px;
}
.toggle-btn.enable:after {
  right: 2px;
}
/*TOGGLE BTN CSS*/

/*TOGGLE BTN MINI CSS*/
.toggle-btn.mini {
  width: 35px;
  height: 18px;
}
.toggle-btn.mini.disable:after,
.toggle-btn.mini.enable:after {
  width: 14px;
  height: 14px;
}
/*TOGGLE BTN MINI CSS*/
/*TABLE CSS*/

/*AUTO COMPLETE FORM*/
.auto-form-container {
  border: 1px solid #c6d4e2;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 35px;
  width: 200px;
  background-color: #fff;
}
.form-container.disable {
  background-color: #f9fafb;
  border: solid 1px #d9e3ec;
}
.form-container.disable input[type="text"] {
  background-color: #f5f9fe;
  pointer-events: none;
  color: #7c91ac;
}
.auto-form-container.disable {
  background-color: #f9fafb;
  border: solid 1px #d9e3ec;
}
.auto-form-container.disable input[type="text"] {
  background-color: #f5f9fe;
  pointer-events: none;
  color: #7c91ac;
}
.auto-form-container.fi input[type="text"] {
  padding-left: 30px;
}
.auto-form-container.toggle-wrap {
  background-color: transparent;
  border: none;
  margin-top: 5px;
}
.fi-icon {
  position: absolute;
  left: 10px;
  bottom: 9px;
  font-size: 13px;
}
.auto-form-container input[type="text"],
input[type="number"] {
  border: none;
  height: 34px;
  width: 100%;
}
.auto-form-container .pdd-r {
  padding-right: 50px;
}
.auto-form-container .fluid-form {
  position: relative;
  width: 100%;
  margin: 5px 0;
}
/*BUTTON IN FORM CSS*/
.auto-form-container .button-form {
  position: absolute;
  right: 8px;
  top: 21px;
  cursor: pointer;
  padding: 2px 5px 2px 10px;
  height: 23px;
  font-size: 13px;
  color: #fff;
  background-color: #4bd2a1;
  border-radius: 5px;
}
.auto-form-container .button-form .icon-right_ic {
  font-size: 18px;
}
.auto-form-container .button-form:hover {
  background-color: #3bb488;
}
/*BUTTON IN FORM CSS*/

/*AKUN TREE SECTION START*/
.akun-tree-wrap {
  position: relative;
  font-size: 14px;
}
.akun-tree-wrap .akun-parent-wrap .akun-parent {
  position: relative;
  width: 245px;
  color: #25344b;
  padding: 3px 0;
}
.akun-tree-wrap .akun-child-wrap {
  border-left: 1px solid #a1afc5;
  padding-left: 12px;
  color: #77879f;
}
.akun-tree-wrap .akun-child-wrap .akun-child {
  padding: 3px 0;
}
.akun-tree-wrap .akun-child-wrap .akun-child .name {
  width: 232px;
}
.akun-tree-wrap .akun-child-wrap .akun-grand-child-wrap {
  border-left: 1px solid #a1afc5;
  padding-left: 12px;
  color: #9eadc3;
}
.akun-tree-wrap
  .akun-child-wrap
  .akun-grand-child-wrap
  .akun-grand-child
  .name {
  width: 219px;
}
/*AKUN TREE SECTION ENDS*/

.form-container .popup-wrap {
  position: absolute;
  left: 0;
  z-index: 2;
  top: 60px;
  width: 205px;
  background-color: #fff;
  box-shadow: 0px 10px 25px 0px rgb(45 46 75 / 23%),
    0px 2px 8px 0px rgb(45 46 75 / 13%);
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 13px;
}
.form-container .popup-wrap ul li {
  list-style: none;
  padding: 7px 0;
  cursor: pointer;
}
.form-container .popup-wrap .text-list {
  color: #4b4b4b;
}
.form-container .popup-wrap ul li:hover .text-list {
  color: #000;
}
.form-container .popup-wrap.lg-wrap {
  width: 280px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.form-container .popup-wrap.lg-wrap ul li {
  border-bottom: 1px solid #e7edf6;
}
.form-container .popup-wrap.lg-wrap .text-list {
  font-weight: 600;
}
.form-container .popup-wrap.lg-wrap .text-desc {
  font-size: 11px;
  color: #77879f;
}
.form-container .popup-wrap.lg-wrap ul.main-list li:last-child {
  border: none;
}
.form-container .popup-wrap.gudang-wrap {
  padding-top: 15px;
}
.auto-form-container ul.input-label {
  margin: 0;
  padding: 3px 6px;
  cursor: default;
}
.auto-form-container ul.input-label li {
  display: inline-block;
  margin-bottom: 1px;
  background-color: #d8ecff;
  color: #5581ab;
  font-size: 10.5px;
  padding: 2px 5px;
  margin: 0 5px 0 0;
  width: auto;
  position: relative;
  border: 1px solid #b5d2f9;
  border-radius: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.auto-form-container ul.input-label li .icon {
  position: relative;
  font-size: 12px;
  top: 2px;
  cursor: pointer;
  border-radius: 2px;
}
.auto-form-container ul.input-label li .icon:hover {
  background-color: #a4c7ea;
  color: #3b6186;
}
.auto-form-container ul.input-label.popup-inside {
  padding: 0;
  margin-top: 7px;
}
.auto-form-container ul.input-label.popup-inside li {
  border: 1px solid #b5d2f9;
  margin-bottom: 3px;
  margin-right: 0;
}
.auto-form-container .reset-list {
  padding: 1px 8px;
  border-radius: 10px;
  background-color: #ffe0e8;
  color: #ac1a3d;
}
.login-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}
.sign-sidebar {
  position: relative;
  background: #f1cdd7;
  color: #865c6c;
  width: 514px;
  flex-grow: 0;
}
.sign-sidebar .photo-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}
.sign-sidebar .photo-section .image {
  background-image: url(./img/side-bg.jpg);
  ms-flex-positive: 1;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.sign-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.sign-content .main-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.sign-content .main-content .auth-content {
  width: 100%;
  max-width: 416px;
  margin: 0;
  padding: 30px 60px 0;
}
.sign-content .main-content .auth-content .title-login {
  font-weight: 700;
  font-size: 18px;
  color: #25344b;
  line-height: 19px;
  margin-bottom: 30px;
}
.sign-content .main-content .auth-content .form-wrap {
  width: 100%;
  margin-bottom: 10px;
}
.sign-content .main-content .auth-content .form-wrap .form-container {
  width: 100%;
}
.sign-content .main-content .auth-content .form-wrap .form-login {
  padding-right: 33px !important;
  width: 100%;
}
.sign-content .main-content .auth-content .forgot-pass a {
  font-size: 11.5px;
  color: #146fb1;
}
.sign-content .main-content .auth-content .forgot-pass a:hover {
  color: #105586;
}
.sign-content .main-content .auth-content .form-sub {
  padding: 10px 70px;
  font-size: 15px;
  margin-top: 30px;
}

/*SELECT POPUP FORM*/
.auto-form-container .popup-setting {
  position: absolute;
  top: 60px;
  left: 0;
  width: 235px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 25px 0px rgba(45, 46, 75, 0.23),
    0px 2px 8px 0px rgba(45, 46, 75, 0.13);
  z-index: 1;
  padding: 10px;
  color: #333;
}
.auto-form-container .popup-setting.popup-right {
  right: 0;
  left: initial;
}
.auto-form-container .popup-setting ul li {
  list-style: none;
  font-size: 12px;
  display: table-cell;
}
.auto-form-container .popup-setting ul li span {
  font-size: 18px;
  vertical-align: middle;
}
/*SELECT POPUP FORM*/

/*SINGLE SELECT*/
.form-container .checkbox {
  font-size: 12px;
  color: #737f8b;
  padding-left: 25px;
}
/*SINGLE SELECT*/

/*POPUP SECTION*/
.window-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 41, 51, 0.65);
  z-index: 2;
}
.window-overlay .window-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 885px;
  height: 550px;
  margin-left: -442.5px;
  margin-top: -275px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 8px rgba(45, 46, 75, 0.13),
    0px 10px 25px rgba(45, 46, 75, 0.23);
}
.window-overlay .window-container.product-window {
  width: 630px;
  /*    height: 725px;*/
  margin-left: -330px;
  margin-top: -270px;
}
.window-overlay .window-container .window-nav {
  position: relative;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #ebedf0;
  padding: 0 20px;
}
.window-overlay .window-container .window-nav .title {
  position: relative;
  height: 100%;
  font-size: 15px;
  font-weight: 700;
}
.window-overlay .window-container .window-nav .tab_content_module {
  position: relative;
  border-bottom: initial;
  height: 54px;
  padding-right: 60px;
}
.window-overlay .window-container .window-content {
  position: relative;
  width: 100%;
  /*    height: 400px;*/
  height: calc(100% - 110px);
  overflow: auto;
  padding: 10px 15px;
}
.window-overlay .window-container .window-content .main-content-module {
  height: calc (100% - 70px);
  margin-top: 0;
}
.window-overlay .window-container .window-content .form-wrap .form-container {
  margin-bottom: 20px;
}
.window-overlay .window-container .window-bottom {
  position: relative;
  bottom: 0;
  right: 0;
}
.window-overlay .window-container .window-bottom .button {
  float: right;
  position: relative;
  right: initial;
  top: initial;
}
.window-overlay .window-container .window-content .product-img {
  position: relative;
  float: left;
  width: 255px;
  height: auto;
}
.window-overlay .window-container .window-content .product-img img {
  width: 100%;
  object-fit: cover;
}
.window-overlay .window-container .window-content .form-side {
  position: relative;
  float: left;
  width: calc(100% - 255px);
  padding-left: 15px;
}
.window-overlay .window-container .window-content .form-side .form-wrap {
  width: 100%;
}
.window-overlay
  .window-container
  .window-content
  .form-side
  .form-wrap
  .form-container {
  margin-bottom: 10px;
}
.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.lg {
  width: 100%;
}
.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.md {
  width: 60%;
}
.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.sm {
  width: 40%;
}
.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.hlf {
  width: 50%;
}

.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.padding-l {
  padding-left: 12px;
}
.window-overlay
  .window-container
  .window-content
  .form-wrap
  .form-container.padding-r {
  padding-right: 12px;
}
.window-overlay
  .window-container
  .window-content
  .form-side
  .auto-form-container {
  width: 100%;
}
.form-container .ppn-desc {
  width: 100%;
  position: relative;
  /*    margin-left: 8px;*/
  line-height: 14px;
}
.form-container .ppn-desc div:first-child {
  font-weight: 700;
  font-size: 14px;
}
.form-container .ppn-desc div:last-child {
  font-size: 11px;
  color: #77879f;
}
.auto-form-container.green-f input[type="text"] {
  background-color: #e7f7f1;
  color: #348178;
}
.form-container .auto-form-container.green-f {
  border: 1px solid #bee6d7;
}
/*POPUP STOK*/
.window-overlay .window-container.product-window.small-popup {
  width: 450px;
  height: 445px;
  margin-left: -225px;
  margin-top: -222px;
}
.window-overlay .window-container.small-popup .window-content .form-side {
  width: 100%;
  padding-left: 0;
}

/*DIALOG BOX*/
.window-overlay .window-container.dialog-box {
  width: 400px;
  height: 200px;
  margin-left: -200px;
  margin-top: -100px;
}
.window-overlay .window-container.dialog-box p {
  font-size: 12px;
  color: #77879f;
}
.window-overlay .window-container.dialog-box .window-bottom .button {
  float: right;
  position: relative;
  right: initial;
  top: initial;
}

/*DIALOG BOX*/

/*FULL TAB CSS */
.main-content-module.detail-page.full-tab {
  margin-top: 5px;
  height: 100%;
}
.main-content-module.detail-page.full-tab .detail-data-wrap .content-wrap {
  width: 100%;
  height: calc(100% - 33px);
  padding: 35px;
  overflow-y: auto;
}
.main-content-module.detail-page.full-tab
  .detail-data-wrap
  .content-wrap
  .main-content-module {
  margin-top: initial;
  height: 100%;
}
.right-content-module.full-tab {
  padding-top: 38px;
}
.main-content-module.detail-page.full-tab .form-wrap {
  width: 360px;
}
.main-content-module.detail-page.full-tab .form-wrap .form-container {
  margin-bottom: 15px;
  width: 100%;
}
.main-content-module.detail-page.full-tab
  .form-wrap
  .form-container
  .auto-form-container {
  width: initial;
}

/*FULL TAB CSS */

/*FULL TAB CONTENT CSS*/
.full-tab .tab-list {
  position: relative;
  padding: 8px 15px;
  background-color: #e0e3e8;
  border-radius: 8px 8px 0 0;
  color: #a2acb6;
  font-size: 12px;
  margin-right: 2px;
  cursor: pointer;
}
.full-tab .tab-list.active {
  background-color: #f2f4f8;
  color: #737f8b;
}
.full-tab .tab-list:hover {
  background-color: #e3e6ed;
  color: #88939f;
}
.full-tab .tab-list.active:hover {
  background-color: #f2f4f8;
  color: #737f8b;
}

.main-content-module.detail-page.full-tab
  .detail-data-wrap
  .content-wrap
  .section-title,
.main-content-module.detail-page
  .detail-data-wrap
  .content-wrap
  .section-title {
  font-size: 20px;
  font-family: "poppins-bold";
  font-weight: 700;
  color: #3a4a59;
  margin-bottom: 10px;
}
.main-content-module.detail-page.full-tab
  .detail-data-wrap
  .content-wrap
  .col-fulltab-50,
.main-content-module.detail-page
  .detail-data-wrap
  .content-wrap
  .col-fulltab-50 {
  position: relative;
  float: left;
  width: 50%;
}
.main-content-module.detail-page.full-tab
  .detail-data-wrap
  .content-wrap
  .toggle-btn {
  margin-left: auto;
}
/*FULL TAB CONTENT CSS*/

/*UPLOAD IMAGE CSS*/
.upload-img-wrap {
  position: relative;
  width: 100%;
  height: 128px;
  padding: 20px 20px;
  border-radius: 12px;
  background-color: #e5eaf3;
  text-align: center;
  /*    border: 1px dashed #CAD3E2;*/
  background-image: repeating-linear-gradient(
      -31deg,
      #cad3e2,
      #cad3e2 8px,
      transparent 8px,
      transparent 15px,
      #cad3e2 15px
    ),
    repeating-linear-gradient(
      59deg,
      #cad3e2,
      #cad3e2 8px,
      transparent 8px,
      transparent 15px,
      #cad3e2 15px
    ),
    repeating-linear-gradient(
      149deg,
      #cad3e2,
      #cad3e2 8px,
      transparent 8px,
      transparent 15px,
      #cad3e2 15px
    ),
    repeating-linear-gradient(
      239deg,
      #cad3e2,
      #cad3e2 8px,
      transparent 8px,
      transparent 15px,
      #cad3e2 15px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.upload-img-wrap .upload-btn-wrap {
  width: 260px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.upload-img-wrap .icon-import_ic {
  font-size: 60px;
  color: #b2bccc;
}
.upload-img-wrap .desc {
  font-size: 12px;
  color: #98a3b5;
}
.upload-img-wrap .upload-btn-wrap:hover .icon-import_ic,
.upload-img-wrap .upload-btn-wrap:hover .desc {
  color: #7a8495;
}
.upload-img-wrap .title-progress {
  font-size: 16px;
  font-family: "Poppins-bold";
  color: #7588a7;
}
.upload-img-wrap .upload-done {
  height: 100%;
  color: #7588a7;
}
.upload-img-wrap .upload-done .photo {
  height: 100%;
  margin-right: 10px;
}
.upload-img-wrap .upload-done .photo img {
  height: 100%;
}
.upload-img-wrap .upload-done .product-name {
  font-size: 12px;
  margin-right: 10px;
}
.upload-img-wrap .upload-done .dlt-btn {
  font-family: "poppins-bold";
  font-size: 12px;
  margin-right: 10px;
}
/*UPLOAD IMAGE CSS*/

/*GUDANG CSS*/
.auto-form-container .gudang-wrap .reset-list {
  position: relative;
  margin-bottom: 20px;
}
/*GUDANG CSS*/

/*SATUAN EQUAL*/
.satuan-equal {
  width: 100%;
}
.satuan-equal .form-container.satuan {
  margin-right: 10px;
  margin-bottom: 10px !important;
}
.satuan-equal .form-container.nominal {
  width: 200px !important;
  margin-bottom: 10px !important;
}
/*SATUAN EQUAL*/

/*BAHAN FORMULA*/
.bahan-wrap{
  width: 427px;
}
.main-alternatif-wrap{
  border-left: 1px solid #c3cede;
  margin-bottom: 15px;
}
.flex-between.bahan-wrap.alternatif .form-container{
  margin-bottom: 0 !important;
}
.flex-between.bahan-wrap.alternatif{
  margin-bottom: 15px;
}
.flex-between.bahan-wrap.alternatif .form-action{
  margin-top: 15px;
}
.bahan-wrap.alternatif{
  padding-left: 18px;
}
.bahan-wrap .form-qty{
  width: 100px  !important;
  margin-left: 10px;
}
.bahan-wrap .form-satuan{
  width: 170px !important;
  margin-left: 10px;
}
.bahan-wrap .form-action{
  width: 70px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}
.bahan-wrap .form-action .icon-wrap{
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  color: #737F8B;
}
.bahan-wrap .form-action .icon-wrap:hover{
  color: #47515c;
}
/*BAHAN FORMULA*/

/*POPUP TAMBAH SATUAN*/
.form-container .popup-wrap.lg-wrap.add-new-satuan {
  width: 360px;
  padding-bottom: 10px;
}
.form-container .popup-wrap.lg-wrap.add-new-satuan .form-wrap {
  width: 100%;
  margin-right: 0;
}
.form-container .popup-wrap.lg-wrap.add-new-satuan .desc {
  width: 100%;
  text-align: center;
  color: #a1afc5;
  padding: 10px 0;
}
.form-container .popup-wrap.lg-wrap.add-new-satuan .button {
  float: left;
}
/*POPUP TAMBAH SATUAN*/

/*KATEGORI BARANG*/
.ktg-utama {
  font-family: "poppins-bold";
}
.sub-ktg {
  padding-left: 25px !important;
}
.sub-sub-ktg {
  padding-left: 50px !important;
}
/*KATEGORI BARANG*/

/*PERMINTAAN FILTER DATE*/
.date-toggle .desc {
  width: 100%;
  font-size: 12px;
  color: #737f8b;
}
/*PERMINTAAN FILTER DATE*/

/*DURATION DATE*/
.duration-wrap {
  font-size: 12px;
  margin-left: 5px;
}
.duration-wrap .days,
.duration-wrap .weeks,
.duration-wrap .months {
  height: 35px;
  padding: 0 9px;
  border: 1px solid #c6d4e2;
  cursor: pointer;
}
.duration-wrap .days:hover,
.duration-wrap .weeks:hover,
.duration-wrap .months:hover {
  background-color: #f3f6f8;
}
.duration-wrap .days {
  border-radius: 8px 0 0 8px;
  border-right: 0;
}
.duration-wrap .weeks {
}
.duration-wrap .months {
  border-radius: 0px 8px 8px 0;
  border-left: 0;
}
.duration-wrap .active {
  color: #fff;
  background-color: #42c0b9;
  border: none;
}
.duration-wrap .active:hover {
  background-color: #42c0b9;
}
/*DURATION DATE*/

/*DATA TOTAL*/
.data-total-wrap {
  position: relative;
  float: left;
  /*    width: 60px;*/
  font-size: 10px;
  color: #b8c3ce;
  text-align: right;
  margin-top: 15px;
}
.data-total-wrap .value {
  font-size: 14.5px;
  color: #737f8b;
  font-weight: 700;
}
/*DATA TOTAL*/

/*DETAIL DATA*/

.detail-data-wrap .data-total-wrap {
  margin-top: 0;
  float: right;
}
/*DETAIL DATA*/

/*NOMOR REKENING*/
.phone-wrap {
  position: relative;
  width: 380px;
}
.phone-wrap .phone-container {
  position: relative;
  width: 100%;
  border-radius: 12px;
  background: #e8ebef;
  text-align: center;
  font-size: 14px;
  color: #b3bcc4;
  padding: 20px 10px;
}
.phone-wrap .phone-container .button {
  margin-top: 5px;
}
.phone-wrap .phone-container.list {
  text-align: left;
  padding: 20px 100px 20px 20px;
  margin-bottom: 20px;
}
.phone-wrap .phone-container .account-num {
  font-size: 19px;
  color: #3a4a59;
  font-weight: 700;
}
.phone-wrap .phone-container .bank-name {
  color: #727e8b;
}
.phone-wrap .phone-container .account-name {
  color: #3a4a59;
}
.phone-wrap .phone-container .action-button-wrap {
  position: absolute;
  height: 24px;
  right: 20px;
  top: 20px;
}
.phone-wrap .phone-container .action-button-wrap .action-button {
  float: left;
  width: 24px;
  height: 24px;
  color: #737f8b;
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
}
.phone-wrap .phone-container .action-button-wrap .action-button:hover {
  color: #3a4a59;
}
/*NOMOR REKENING*/

/*DOKUMENTASI TRANSAKSI*/
.product-window .window-content .upload-img-wrap {
  float: left;
  height: 68px;
  padding: 10px 20px;
  border-radius: 8px;
}
.product-window .window-content .upload-img-wrap .upload-btn-wrap {
  width: 210px;
}
.product-window .window-content .upload-img-wrap .desc {
  float: left;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
}
.product-window .window-content .upload-img-wrap .icon-import_ic {
  float: left;
  font-size: 40px;
}
.product-window .window-content .uploaded-wrap {
  float: left;
  width: 100%;
  padding-top: 10px;
}
.product-window .window-content .uploaded-wrap .uploaded-list {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eff3f6;
}
.product-window .window-content .uploaded-wrap .uploaded-list .file-detail {
  flex: 1;
}
.product-window
  .window-content
  .uploaded-wrap
  .uploaded-list
  .file-detail
  .file-name {
  font-size: 12px;
  font-weight: 600;
}
.product-window
  .window-content
  .uploaded-wrap
  .uploaded-list
  .file-detail
  .file-size {
  font-size: 9.5px;
  color: #a1afc5;
}
.product-window .window-content .uploaded-wrap .uploaded-list .file-action {
  display: flex;
  align-items: center;
}
.product-window
  .window-content
  .uploaded-wrap
  .uploaded-list
  .file-action
  .time-info {
  float: left;
  font-size: 11px;
  color: #a1afc5;
}
.product-window
  .window-content
  .uploaded-wrap
  .uploaded-list
  .file-action
  .action-wrap {
  float: left;
  padding-left: 5px;
  color: #77879f;
}
.product-window .window-content .form-wrap .uploaded-wrap .uploaded-list .icon {
  position: relative;
  right: initial;
  bottom: initial;
  float: left;
  font-size: 20px;
  padding: 2px;
  cursor: pointer;
}
.product-window
  .window-content
  .form-wrap
  .uploaded-wrap
  .uploaded-list
  .icon:hover {
  color: #25344b;
}

/*DOKUMENTASI TRANSAKSI*/

/*INFORMASI FAKTUR*/
.col-content {
  position: relative;
  float: left;
  margin-right: 10px;
  width: 360px;
}
.col-content .row {
  width: 100%;
  display: flex;
}
.col-content .row .label {
  flex-grow: 1;
  font-size: 13px;
  padding: 10px 0;
  border-bottom: 1px solid #e2e5e9;
}

/*INFORMASI FAKTUR*/

/*INFORMASI KIRIM BARANG*/
.col-content .action {
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
}
.col-content .action.green {
  background-color: #c5f2e7;
}
.col-content .action .row .label {
  padding: 0;
  border-bottom: none;
  color: #357767;
}
/*INFORMASI KIRIM BARANG*/

/*PERMINTAAN BARANG*/
.col-content .permintaan-log {
  border-bottom: solid 1px #e2e5e9;
  padding: 5px 8px;
  cursor: pointer;
  /*    border-radius: 12px;*/
}
.col-content .permintaan-log:hover {
  background-color: #e7ebf2;
}
.col-content .permintaan-log .number {
  font-size: 13px;
  color: #6b95ff;
}
.col-content .permintaan-log .date {
  font-size: 10px;
}
/*PERMINTAAN BARANG*/

/*PEMBAYARAN BARANG*/
.total-wrap .title {
  font-size: 10px;
  color: #b8c3ce;
}
.total-wrap .amount {
  font-size: 15px;
  color: #737f8b;
}
/*PEMBAYARAN BARANG*/

.progress-bar {
  width: 250px;
  height: 20px;
  border-radius: 4px;
  background-color: #a1afc5;
}

.progress {
  height: 100%;
  background-color: #88939f;
  border-radius: 4px;
  width: 0;
  transition: width 0.5s ease-in-out; /* Add animation for smooth progress */
}

.main-content-module.detail-page.full-side-tab .form-wrap.w-full {
  width: 360px;
}
.main-content-module.detail-page.full-side-tab
  .form-wrap.w-full
  .form-container {
  margin-bottom: 15px;
  width: 100%;
}
.main-content-module.detail-page.full-side-tab
  .form-wrap.w-full
  .form-container
  .auto-form-container {
  width: initial;
}
